import { Tab } from '@headlessui/react'
import ContentLoader from 'react-content-loader'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const BasicSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className="w-full"
      height={160}
    >
      <rect
        x="0"
        y="0"
        rx="3"
        ry="3"
        height="24"
        className="w-full rounded-lg"
      />
      <rect
        x="0"
        y="34"
        rx="3"
        ry="3"
        height="24"
        className="w-full rounded-lg"
      />
      <rect
        x="0"
        y="68"
        rx="3"
        ry="3"
        height="24"
        className="w-full rounded-lg"
      />
      <rect
        x="0"
        y="102"
        rx="3"
        ry="3"
        height="24"
        className="w-full rounded-lg"
      />
      <rect
        x="0"
        y="136"
        rx="3"
        ry="3"
        height="24"
        className="w-full rounded-lg"
      />
    </ContentLoader>
  )
}

const Skeleton = () => {
  return (
    <Tab.Panels className="mt-2 flex h-[200px] w-full min-w-[480px]">
      <Tab.Panel
        className={classNames(
          'rounded-xl bg-white p-3 mb-2 w-full h-full  ',
          'ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2 overflow-y-auto'
        )}
      >
        <BasicSkeleton />
      </Tab.Panel>
    </Tab.Panels>
  )
}

export const SmallScreenSkeleton = () => {
  return (
    <div className="mt-2 flex h-[200px] w-full ">
      <div className="       rounded-xl bg-white p-3 mb-2 w-full h-full ring-white/60 ring-offset-2 ring-offset-green-400 focus:outline-none focus:ring-2 overflow-y-auto">
        <BasicSkeleton />
      </div>
    </div>
  )
}

export default Skeleton
